<script setup lang="ts">
import VendorShowcase from "~/components/home/VendorShowcase.vue";
import CTAHome from "~/components/home/CTAHome.vue";
import TestimonialsHome from "~/components/home/TestimonialsHome.vue";

useSeoMeta({
  title: 'Best 3D Model, Texture, Graphics Shop and Asset Marketplace | MCModels',
  description: 'Find and purchase high-quality 3D models, textures, and graphics at MCModels. Elevate your projects with our extensive collection of assets tailored for game developers, architects, and creatives.',
  ogTitle: 'MCModels: Premier Marketplace for 3D Assets',
  ogDescription: 'Discover a vast selection of 3D models, textures, and graphics perfect for various applications. Join MCModels to access top-tier assets and enhance your creative projects.',
  ogImage: () => `/img/logo_full_black_red.png`,
  ogUrl: 'https://mcmodels.net/',
  twitterCard: 'summary_large_image'
})
</script>

<template>
  <header class="sm:flex min-h-[30vh] justify-center pb-4">
    <div class="justify-center items-center sm:w-2/5 mt-8 sm:mt-0 hidden lg:block">
      <NuxtImg src="/img/left.png" alt="The Minecraft steve!" width="750" height="750" class="h-auto" format="webp"/>
    </div>
    <div class="md:w-4/5 lg:w-1/2">
      <div class="flex flex-col justify-center items-center h-full px-4 lg:px-0">
        <HomeHeaderText/>
        <p class="mt-2 text-lg leading-7 text-gray-900 dark:text-gray-200 pt-4">
          MCModels is your home for the highest quality assets curated from the best artists, developers and creators in
          this community. Find the perfect asset for your next project whether it be weapons, tools, armor, mobs,
          bosses, sounds, UI's and more! The best part is, everything on here is made to be mod-free!
        </p>
        <p class="text-lg leading-7 text-gray-900 dark:text-gray-200 pt-4">
          All our vendors are thoroughly vetted and held to a high quality standard so you can be sure you're buying the
          best of the best. Checkout with easy to use payment options and get your assets instantly! Save them to your
          profile for easy access and forever downloads.
        </p>


        <NuxtLink href="/products" prefetch>
          <button
              class="px-5 py-3 text-xl font-bold btn btn-primary mt-6 md:mt-16 w-full h-12 hover:scale-105 transform transition-transform ease-in-out">
            <span class="text-lg font-bold">Shop Now</span>
          </button>
        </NuxtLink>
      </div>
    </div>
    <div class="justify-center items-center sm:w-2/5 mt-8 sm:mt-0 hidden lg:block">
      <NuxtImg format="webp" src="/img/right3.png" alt="The Minecraft steve!" width="750" height="750" class="h-auto"/>
    </div>
  </header>

  <div class="border-b border-gray-900/10 dark:border-zinc-700 w-3/4 mx-auto"></div>

  <productCarousel
      endpoint="/shop/products?disable_pagination=true&sort_by=latest&is_exclusive=true&exclude_non_exclusive_builds=true&limit=25"
      class="my-6 w-5/6 mx-auto">
    <template v-slot:title>
      <h2 class="text-2xl font-bold text-gray-900 dark:text-white">Latest Exclusives</h2>
    </template>
  </productCarousel>

  <HomeNews/>

  <productCarousel
      endpoint="/shop/products?disable_pagination=true&sort_by=sales&sort_order=desc&limit=15&is_exclusive=true&min_price=1&&exclude_non_exclusive_builds=true"
      class="my-6 w-5/6 mx-auto">
    <template v-slot:title>
      <h2 class="text-2xl font-bold text-gray-900 dark:text-white">Top Selling</h2>
    </template>
  </productCarousel>
  <productCarousel
      endpoint="/shop/products?disable_pagination=true&sort_by=random&limit=25&is_exclusive=true&exclude_non_exclusive_builds=true"
      class="my-6 w-5/6 mx-auto pb-6">
    <template v-slot:title>
      <h2 class="text-2xl font-bold text-gray-900 dark:text-white">Random resources</h2>
    </template>
  </productCarousel>

  <VendorShowcase/>

  <TestimonialsHome/>

  <CTAHome/>

</template>