<script lang="ts">
import {defineComponent} from "vue";

export default defineComponent({
  name: "CTAHome",
});
</script>

<template>
  <div class="bg-gray-100 dark:bg-zinc-900">
    <div class="px-6 py-24 sm:px-6 sm:py-32 lg:px-8">
      <div class="mx-auto max-w-2xl text-center">
        <h2 class="text-3xl font-bold tracking-tight text-gray-900 sm:text-4xl dark:text-white">Enhance your project.<br />Start shopping our assets today.</h2>
        <p class="mx-auto mt-6 max-w-xl text-lg leading-8 text-gray-600 dark:text-white">We are the one stop shop for all your quality controlled and curated asset needs, check us out.</p>
        <div class="mt-10 flex items-center justify-center gap-x-6">
          <a href="/products" class="rounded-md bg-primary-500 px-3.5 py-2.5 text-sm font-semibold text-white shadow-sm hover:bg-primary-600 focus-visible:outline focus-visible:outline-2 focus-visible:outline-offset-2 focus-visible:outline-indigo-600">Shop Now</a>
          <a href="/guides" class="text-sm font-semibold leading-6 text-gray-900 dark:text-white">Learn more <span aria-hidden="true">→</span></a>
        </div>
      </div>
    </div>
  </div>
</template>