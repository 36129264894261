<script lang="ts">
import {defineComponent} from 'vue'

export default defineComponent({
  name: "HeaderText",
  data() {
    return {
      currentObject: 'user',
      userTypes: ['Entrepreneurs', 'Server Owners', 'Developers'],
      userTypesIndex: 0,
      resourceTypes: ['Mods', 'Resource Packs', 'Sounds', 'Models', 'Textures', 'Mobs'],
      resourceTypesIndex: 0,
    }
  },
  mounted() {
    this.startTyping()
  },
  methods: {
    startTyping() {
      setTimeout(() => {
        this.currentObject = this.currentObject === 'user' ? 'resource' : 'user'
        if (this.currentObject === 'user') {
          this.userTypesIndex = this.userTypesIndex + 1
          if (this.userTypesIndex >= this.userTypes.length) {
            this.userTypesIndex = 0
          }
        } else if (this.currentObject === 'resource') {
          this.resourceTypesIndex = this.resourceTypesIndex + 1
          if (this.resourceTypesIndex >= this.resourceTypes.length) {
            this.resourceTypesIndex = 0
          }
        }
      }, 3000)

    },
  }
})
</script>

<template>
  <div class="text-3xl font-bold leading-[3.5rem] text-gray-800 dark:text-white">
    <div class="w-full">
      <h1 class="text-center">
        Helping <span class="font-black text-primary-500"><TypingEffect @done="startTyping()" :word-to-type="userTypes[userTypesIndex]"/></span> create
      </h1>
      <div class="flex justify-center">
        with
        <div class="inline-block -rotate-3 transform">
          <span class="ml-2 w-fit rounded-md border-8 border-primary-500 px-4 font-black text-primary-500"><TypingEffect @done="startTyping()" :word-to-type="resourceTypes[resourceTypesIndex]"/></span>
        </div>
      </div>

    </div>
  </div>

</template>