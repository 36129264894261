<script lang="ts">
import {defineComponent} from "vue";
import testimonialsData from "@/assets/testimonials.json"
import type {Testimonial} from "~/types/types";

export default defineComponent({
  name: "TestimonialsHome",
  data() {
    const testimonials: Testimonial[] = testimonialsData;
    return { testimonials };
  }
});
</script>

<template>
  <div class="bg-gray-100 dark:bg-zinc-900 py-24 sm:py-32">
    <div class="mx-auto max-w-7xl px-6 lg:px-8">
      <div class="mx-auto max-w-xl text-center">
        <h2 class="text-lg font-semibold leading-8 tracking-tight text-primary-600">Testimonials</h2>
        <p class="mt-2 text-3xl font-bold tracking-tight text-gray-900 sm:text-4xl dark:text-white">We work with thousands of amazing creators</p>
      </div>
      <div class="mx-auto mt-16 flow-root max-w-2xl sm:mt-20 lg:mx-0 lg:max-w-none">
        <div class="-mt-8 sm:-mx-4 sm:columns-2 sm:text-[0] lg:columns-3">
          <div v-for="testimonial in testimonials" :key="testimonial.author.handle" class="pt-8 sm:inline-block sm:w-full sm:px-4">
            <figure class="rounded-2xl bg-gray-50 p-8 text-sm leading-6 dark:text-white dark:bg-zinc-800">
              <blockquote class="text-gray-900 dark:text-white">
                <p>{{ `“${testimonial.body}”` }}</p>
              </blockquote>
              <figcaption class="mt-6 flex items-center gap-x-4">
                <NuxtImg format="webp" height="40" width="40" class="h-10 w-10 rounded-full bg-gray-50" :src="testimonial.author.imageUrl" alt="" />
                <div>
                  <div class="font-semibold text-gray-900 dark:text-white">{{ testimonial.author.name }}</div>
                  <div class="text-gray-600 dark:text-white">{{ `${testimonial.author.handle}` }}</div>
                </div>
              </figcaption>
            </figure>
          </div>
        </div>
      </div>
      <div class="mx-auto max-w-xl text-center">
        <p class="mt-8 text-xl font-bold tracking-tight text-gray-900 dark:text-white">... and so much more!</p>
      </div>
    </div>
  </div>
</template>