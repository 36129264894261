<template>
  <div class="inline-block">
    {{ state }}
    <span v-if="isCursorVisible" class="cursor" :class="{ blink: isCursorVisible }"></span>
  </div>
</template>

<style scoped>
.cursor {
  border-left: 2px solid #ef4444; /* Adjust the border properties as needed */
  transform: translateY(10px);
  display: inline-block;
  height: 1.1em; /* Adjust the height to match your text size */
  margin-left: 2px; /* Adjust the margin as needed */
}

.blink {
  animation: blink-animation 1s steps(5, start) infinite;
}

@keyframes blink-animation {
  to {
    visibility: hidden;
  }
}
</style>

<script>
/**
 * Default state: "mods" (via prop)
 * When it gets a new state
 * untype the current word
 * type the new word
 * emit event "done" when done
 */
export default {
  name: "Typing",
  props: {
    wordToType: {
      type: String,
      required: true,
    },
    deletingSpeed: {
      type: Number,
      default: 75,
    },
    typingSpeed: {
      type: Number,
      default: 75,
    },
    pause: {
      type: Number,
      default: 2000,
    }
  },
  emits: ["done"],
  data() {
    return {
      state: null,
      typedText: "",
      currentIndex: 0,
      isCursorVisible: false,
    };
  },
  watch: {
    wordToType() {
      this.startDeleting()
    }
  },
  methods: {
    startDeleting() {
      if (this.state.length > 0) {
        this.state = this.state.substring(0, this.state.length - 1);
        setTimeout(() => {
          this.isCursorVisible = !this.isCursorVisible;
          this.startDeleting();
        }, this.deletingSpeed);
      } else {
        setTimeout(() => {
          this.startTyping()
        }, this.pause)
      }

    },
    startTyping() {
      if (this.currentIndex < this.wordToType.length) {
        this.state += this.wordToType[this.currentIndex];
        this.currentIndex++;
        setTimeout(() => {
          this.startTyping();
        }, this.typingSpeed);
      } else {
        this.currentIndex = 0;
        this.isCursorVisible = false;
        this.$emit("done");
      }
    },
  },
  mounted() {
    this.state = this.wordToType;
  },
};
</script>